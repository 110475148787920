import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const AppBar = ({ pages, title, backToPath }) => {
  const [anchorElNav, setAnchorElNav] = useState(false);
  const navigate = useNavigate();
  const { user, logout, logoutMidlet } = useAuth();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (path) => {
    setAnchorElNav(false);
    if (path) {
      navigate(path);
    }
  };

  const handleBackToPath = () => {
    navigate(backToPath.pathName, { state: backToPath.state });
  }

  return (
    <MuiAppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            {title}
          </Typography>

          <Box mr={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            { window.location.pathname !== "/user-accounts" && pages &&
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            }
          </Box>
          <Typography
            align="center"
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "block", md: "none" } }}
          >
            {title}
          </Typography>
          <Box mr={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} textAlign="right">
            { backToPath &&
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                onClick={handleBackToPath}
                color="inherit"
              >
                <ArrowBackIcon />
              </IconButton>
            }
          </Box>
        </Toolbar>
        { window.location.pathname !== "/user-accounts" && pages &&
        <Drawer
          open={anchorElNav}
          onClose={handleCloseNavMenu}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
          >
            <DrawerHeader>
              {user.gameType &&
                <Typography component="div" sx={{width: "100%", textAlign: "center"}}>
                  {user.gameType}
                </Typography>
              }
              <IconButton onClick={handleCloseNavMenu}>
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
            {pages?.map((page, index) => (
              <ListItem key={page.label} disablePadding>
                { page.path === "logout" ? (
                  <ListItemButton onClick={user.userAccounts ? logoutMidlet : logout}>
                    <ListItemIcon>
                      { page.icon }
                    </ListItemIcon>
                    <ListItemText primary={page.label} primaryTypographyProps={{fontSize: 20}} />
                  </ListItemButton>
                ) : (
                  <ListItemButton onClick={() => handleCloseNavMenu(page.path)}>
                    <ListItemIcon>
                      { page.icon }
                    </ListItemIcon>
                    <ListItemText primary={page.label} primaryTypographyProps={{fontSize: 20}} />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
            </List>
          </Box>
        </Drawer>
        }
      </Container>
    </MuiAppBar>
  );
};
