import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apiHandle, verifyPassword } from "../../hooks/services/apiServices";
import { useAuth } from "../../hooks/useAuth";
import { isPostErrorResult, toastErr } from "../../utils/utils";

import ShareButton from "../../components/ShareButton";
import ShareDialog from "../../components/ShareDialog";
import { Container, Box, Button, TextField, Toolbar, CircularProgress, Typography } from "@mui/material";
import { List, ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { MenuList, MenuItem } from "@mui/material";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import ShareIcon from '@mui/icons-material/Share';
import LoadingButton from '@mui/lab/LoadingButton';

const APP_AGENT = process.env.REACT_APP_AGENT;

export const HistorySearchResultPage = ({ setTitle, setBackToPath }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [resultContent, setResultContent] = useState();
  const [resultHeader, setResultHeader] = useState();
  const [resultList, setResultList] = useState([]);
  const [invoiceno, setInvoiceno] = useState();
  const [invoicemsg, setInvoicemsg] = useState();
  const [password, setPassword] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isPwdDialogOpen, setPwdDialogOpen] = useState(false);
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const [isInvoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [isProgressing, setProgressing] = useState(true);
  const [isInvoiceProgressing, setInvoiceProgressing] = useState(false);
  const [isLoadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setTitle(t("menu.item.history"));

    if (location.state) {
      setBackToPath({ pathName: "/history/search", state: {
        searchTitle: location.state.searchTitle,
        searchType: location.state.searchType
      }});
      handleSearchResult(location.state);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchResult = (data) => {
    apiHandle.getHistorySearch({
      token: user.accessToken,
      searchType: data.searchType,
      searchNumber: data.searchNumber,
      searchDate: data.searchDate
    }).then(result => {
      if (result.data) {
        setResultContent(result.data);

        if (data.searchType === "list") {
          generateSimpleInvoiceList(result.data, data.searchType);
        } else generateInvoiceList(result.data, data.searchType);
      } else toastErr(t("error.common.app"));
      setProgressing(false);
    }).catch(e => {
      setProgressing(false);
      toastErr(e);
    });
  }

  const generateSimpleInvoiceList = (data, type) => {
    const list = data.trim().split("\n");
    let invoiceno = "";
    let htmlStr = "";
    let resultInfoList = [];

    list.forEach((item, index) => {
      if (index === 0) setResultHeader(item);
      else {
        if (item.startsWith("(")) {
          invoiceno = item.substring(1, item.indexOf(")"));
          htmlStr = item.substring(item.indexOf(")") + 1);
          resultInfoList.push({invoiceno: invoiceno, htmlStr: htmlStr, type: type});
        }
      }
    });
    setResultList(resultInfoList);
  }

  const generateInvoiceList = (data, type) => {
    const list = data.trim().split("\n");
    let index = 0;
    let invoiceno = "";
    let htmlStr = "";
    let resultInfoList = [];

    list.forEach(item => {
      if ((APP_AGENT === "BR" && item.startsWith("[---") && item.endsWith("---]"))
        || (APP_AGENT === "HWIN" && item.startsWith("(") && item.endsWith(")"))) {
        if (index === 0) {
          setResultHeader(htmlStr);
        } else {
          resultInfoList.push({invoiceno: invoiceno, htmlStr: htmlStr});
        }
        if (APP_AGENT === "BR" && item.startsWith("[---") && item.endsWith("---]"))
          invoiceno = item.replace("[---", "").replace("---]", "");
        else invoiceno = item.replace("(", "").replace(")", "");
        htmlStr = "";
        index++;
      } else htmlStr += item + "\n";
    });

    if (htmlStr.length > 0) {
      if (resultInfoList.length === 0) {
        if (invoiceno.length > 0) {
          resultInfoList.push({invoiceno: invoiceno, htmlStr: htmlStr, type: type});
        // if not invoice found
        } else setResultHeader(htmlStr);
      } else {
        resultInfoList.push({invoiceno: invoiceno, htmlStr: htmlStr});
      }
    }
    setResultList(resultInfoList);
  }

  const handleItemClick = (item) => {
    if (item.type === "list") {
      setInvoiceDialogOpen(true);
      setInvoiceProgressing(true);

      apiHandle.getInvoiceDetail({
        token: user.accessToken,
        invoiceno: item.invoiceno
      }).then(result => {
        if (result.data) {
          setInvoiceno(item.invoiceno);
          setInvoicemsg(result.data);
        } else toastErr(t("error.common.app"));
        setInvoiceProgressing(false);

      }).catch(e => {
        toastErr(e);
        setInvoiceProgressing(false);
      });
    } else {
      setInvoiceno(item.invoiceno);
      setInvoicemsg(item.htmlStr);
      setDialogOpen(true);
    }
  }

  const handleOptionClose = (option, msg) => {
    setDialogOpen(false);
    setSelectedOption(option);

    switch(option) {
      case "resend": // resend
      case "void": // void
        setPwdDialogOpen(true);
        break;
      case "edit": // edit
        apiHandle.editOrder({
          token: user.accessToken,
          invoiceno: invoiceno
        }).then(result => {
          if (result.data) {
            navigate("/history/search-order", {
              state: {
                ...location.state,
                orderResult: result.data,
                action: "EDIT"
              }
            });
          } else toastErr(t("error.common.app"));
        }).catch(e => {
          toastErr(e);
        });
        break;
      case "share":
        setShareDialogOpen(true);
        break;
      default:
        break;
    }
  }

  const handlePwdDialogClose = () => {
    setPwdDialogOpen(false);
    setPassword();
  }

  const handleSubmitVoid = () => {
    if (verifyPassword({
      token: user.accessToken,
      password: password
    })) {
      setLoadingButton(true);

      if (selectedOption === "void") {
        apiHandle.cancelOrder({
          token: user.accessToken,
          invoiceno: invoiceno
        }).then(result => {
          setPwdDialogOpen(false);
          setLoadingButton(false);

          if (isPostErrorResult(result.data)) {
            toastErr(result.data);
          } else {
            navigate("/history/search-order", {
              state: {
                ...location.state,
                orderResult: result.data,
                action: "CANCELLED"
              }
            });
          }
        }).catch(e => {
          setLoadingButton(false);
          toastErr(e);
        });
      } else if (selectedOption === "resend") {
        apiHandle.resendOrder({
          token: user.accessToken,
          invoiceno: invoiceno
        }).then(result => {
          setPwdDialogOpen(false);
          setLoadingButton(false);

          if (isPostErrorResult(result.data)) {
            toastErr(result.data);
          } else {
            navigate("/history/search-order", {
              state: {
                ...location.state,
                orderResult: result.data,
                action: "CANCELLED"
              }
            });
          }
        }).catch(e => {
          setLoadingButton(false);
          toastErr(e);
        });
      }
    } else toastErr(t("error.password.invalid"));
  }

  return (
    <Container component="main" maxWidth="xs">
      { isProgressing ?
        <Box sx={{ marginTop: 9, flexGrow: 1, textAlign: "center" }}>
          <CircularProgress />
        </Box>
      :
        <Box sx={{ marginTop: 7, flexGrow: 1 }}>
          <Toolbar disableGutters>
            <ShareButton content={resultContent}/>
          </Toolbar>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            { resultHeader &&
              <>
                <ListItem key="header">
                  <ListItemText>{resultHeader.split("\n").map(str => <div>{str}</div>)}</ListItemText>
                </ListItem>
                <Divider key="header1" component="li"/>
              </>
            }
            { resultList?.map((item, index) =>
              <>
                <ListItem key={index} onClick={() => handleItemClick(item)}>
                  <ListItemText sx={{color: (item.htmlStr.includes("CANCELLED") || item.htmlStr.includes(" V ")) ? '#ff0000' : '#000000'}}>
                    {item.htmlStr.split("\n").map((str, index) => <div key={index}>{str}</div>)}
                  </ListItemText>
                </ListItem>
                <Divider key={"div" + index} component="li"/>
              </>
            )}
          </List>
        </Box>
      }

      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
        {t("button.label.options")}
        </DialogTitle>
        <DialogContent>
          <MenuList sx={{ minWidth: 250 }}>
            <MenuItem onClick={() => handleOptionClose("resend")}>
              <ListItemIcon><ScheduleSendIcon fontSize="small" /></ListItemIcon><ListItemText>{t("option.label.resend")}</ListItemText>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => handleOptionClose("edit")}>
              <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon><ListItemText>{t("option.label.edit")}</ListItemText>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => handleOptionClose("void")}>
              <ListItemIcon><CancelIcon fontSize="small" /></ListItemIcon><ListItemText>{t("option.label.void")}</ListItemText>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => handleOptionClose("share")}>
              <ListItemIcon><ShareIcon fontSize="small" /></ListItemIcon><ListItemText>{t("button.label.share")}</ListItemText>
            </MenuItem>
          </MenuList>
        </DialogContent>
      </Dialog>

      <Dialog open={isPwdDialogOpen} onClose={handlePwdDialogClose}>
        <DialogTitle>{selectedOption === "void" ? t("option.label.void") : t("option.label.resend")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedOption === "void" ? t("dialog.password.void.desc") : t("dialog.password.resend.desc")}
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePwdDialogClose} color="secondary">{t("button.label.cancel")}</Button>
          {isLoadingButton ? (
            <LoadingButton
              loading
              variant="outlined"
            >
              {selectedOption === "void" ? t("button.label.void") : t("button.label.resend")}
            </LoadingButton>
          ) : (
            <Button onClick={handleSubmitVoid} color="error" variant="contained">
              {selectedOption === "void" ? t("button.label.void") : t("button.label.resend")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ShareDialog content={invoicemsg} isShareDialogOpen={isShareDialogOpen} setShareDialogOpen={setShareDialogOpen}/>

      <Dialog open={isInvoiceDialogOpen} onClose={() => setInvoiceDialogOpen(false)}>
        <DialogContent sx={{minWidth:250}}>
          {isInvoiceProgressing ?
            <Box sx={{flexGrow: 1, textAlign: "center"}}>
              <CircularProgress />
            </Box>
          :
          <Typography component="div">
            {invoicemsg && invoicemsg.split("\n").map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </Typography>
         }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInvoiceDialogOpen(false)} color="secondary">{t("button.label.cancel")}</Button>
          <Button onClick={() => setDialogOpen(true)} variant="contained">
            {t("button.label.options")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};