import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { apiHandle } from "../../hooks/services/apiServices";
import { isPostErrorResult, convertCounter, toastErr } from "../../utils/utils";

import { Container, Box, Button, Toolbar, CircularProgress, Fab, TextField } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import LoadingButton from '@mui/lab/LoadingButton';

const arr = Array.apply(0, Array(10));
const defaultOrderParams = {
  counter: "M",
  number: "",
  orderBig: "",
  orderSmall: "", 
  order4A: "",
  orderA: "",
  orderC: ""
};

export const SimpleOrderPage = ({ setTitle, setBackToPath }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentFocusField, setCurrentFocusField] = useState();
  const [drawDay, setDrawDay] = useState();
  const [drawCounter, setDrawCounter] = useState();
  const [selectedDrawDay, setSelectedDrawDay] = useState();
  const [selectedDrawCounter, setSelectedDrawCounter] = useState();
  const [orderParams, setOrdeParams] = useState([]);

  const [isLoadingButton, setLoadingButton] = useState(false);
  const [isProgressing, setProgressing] = useState(true);

  useEffect(() => {
    setTitle(t("menu.item.order"));
    setBackToPath({ pathName: "/order" });

    // initial order text table
    handleReset();

    apiHandle.getDrawDayList({
      token: user.accessToken
    }).then(result => {
      if (result.data) {
        const list = result.data.split("#");
        const day = list[0].split(",");
        const counter = list[1].split(",");

        setDrawDay(day);
        setDrawCounter(counter);
        setSelectedDrawDay(0);
        setSelectedDrawCounter(counter[0]);
        setProgressing(false);
      } else toastErr(t("error.common.app"));
    }).catch(e => {
      toastErr(e);
      setProgressing(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingButton(true);
    const data = new FormData(event.currentTarget);

    let orderText = drawDay[selectedDrawDay];
    const phoneNumber = data.get("phone");

    orderParams.forEach(item => {
      if (item.counter !== "" && item.number !== "" 
        && (item.orderBig !== "" || item.orderSmall !== "" || item.order4A !== "" || item.orderA !== "" || item.orderC !== "")) {
        
        const numberLength = item.number.replaceAll("*", "").length;

        orderText += "," + convertCounter(item.counter) + "," + item.number;
        if (item.orderBig !== "") orderText += "#" + item.orderBig;
        else if (numberLength === 4) orderText += "#0";

        if (item.orderSmall !== "") orderText += "#" + item.orderSmall;
        else if (numberLength === 4) orderText += "#0";

        if (user.informat?.substring(1).startsWith("BS4AAC") || user.informat?.substring(1).startsWith("BS4ACA")
          || user.informat?.substring(1).startsWith("SB4AAC") || user.informat?.substring(1).startsWith("SB4ACA")) {
          
          if (item.orderA !== "") orderText += "#" + item.orderA;
          else if (numberLength === 4) orderText += "#0";

          if (item.orderC !== "") orderText += "#" + item.orderC;
          else orderText += "#0";

          if (item.order4A !== "") orderText += "#" + item.order4A;
          else orderText += "#0";

        } else if (user.informat?.substring(1).startsWith("BSCA4A") || user.informat?.substring(1).startsWith("BSAC4A")
          || user.informat?.substring(1).startsWith("SBCA4A") || user.informat?.substring(1).startsWith("SBAC4A")) {
          
          if (item.orderA !== "") orderText += "#" + item.orderA;
          else orderText += "#0";

          if (item.orderC !== "") orderText += "#" + item.orderC;
          else orderText += "#0";

          if (item.order4A !== "") orderText += "#" + item.order4A;
          else if (numberLength === 4) orderText += "#0";

        } else {
          if (item.order4A !== "") orderText += "#" + item.order4A;
        }
      }
    });

    if (phoneNumber && phoneNumber.length > 0) orderText += "," + phoneNumber;

    apiHandle.order({
      token: user.accessToken,
      orderText: orderText
    }).then(result => {
      if (result.data && result.data !== "") {
        if (isPostErrorResult(result.data)) {
          toastErr(result.data);
        } else {
          let resultArr = result.data.split(";");

          navigate("/order/result", {
            state: {
              fromPath: "/order/simple",
              result: resultArr[0],
              invoiceno: resultArr[1]
            }
          });
        }
      } else toastErr(t("error.common.app"));
      setLoadingButton(false);
    }).catch(e => {
      toastErr(e);
    })
  };

  const handleReset = () => {
    setOrdeParams(arr.map(item =>
      defaultOrderParams
    ));
  }

  const handleCounterClick = (ctr) => {
    if (currentFocusField?.field === 'counter') {
      setOrdeParams(orderParams.map((item, index) =>
        index >= currentFocusField.index ? {
          ...item,
          counter: (
            item.counter.includes(ctr) ? item.counter.replace(ctr, "") : item.counter + ctr
          )
        } : item
      ));
    }
  }

  const handleBoxButtonClick = (type) => {
    if (currentFocusField?.field === 'number') {
      const params = orderParams;
      
      if (params[currentFocusField.index].number === "") {
        toastErr(t("error.order.number.empty"));
      } else {
        setOrdeParams(params.map((item, index) =>
          index === currentFocusField.index ? {
            ...item,
            number: (
              type === "ibox" ? (item.number.startsWith("**") ? item.number.substring(2) : (item.number.startsWith("*") ? "*" + item.number : "**" + item.number))
              : type === "box" ? (item.number.startsWith("**") ? item.number.substring(1) : (item.number.startsWith("*") ? item.number.substring(1) : "*" + item.number))
              : type === "4ds" ? (item.number.endsWith("**") ? item.number.slice(0, -2) : item.number + "**")
              : item.number
            )
          } : item
        ));
      }
    }
  }

  const handleDayChange = (event) => {
    setSelectedDrawDay(event.target.value);
    setSelectedDrawCounter(drawCounter[event.target.value]);
  }

  const handleValueChange = (event) => {
    if (currentFocusField?.field === 'number') {
      setOrdeParams(orderParams.map((item, index) =>
        index === currentFocusField.index ? {
          ...item,
          number: event.target.value
        } : item
      ));
    } else if (currentFocusField?.field === 'big') {
      setOrdeParams(orderParams.map((item, index) =>
        index === currentFocusField.index ? {
          ...item,
          orderBig: event.target.value
        } : item
      ));
    } else if (currentFocusField?.field === 'small') {
      setOrdeParams(orderParams.map((item, index) =>
        index === currentFocusField.index ? {
          ...item,
          orderSmall: event.target.value
        } : item
      ));
    } else if (currentFocusField?.field === '4a') {
      setOrdeParams(orderParams.map((item, index) =>
        index === currentFocusField.index ? {
          ...item,
          order4A: event.target.value
        } : item
      ));
    } else if (currentFocusField?.field === 'a') {
      setOrdeParams(orderParams.map((item, index) =>
        index === currentFocusField.index ? {
          ...item,
          orderA: event.target.value
        } : item
      ));
    } else if (currentFocusField?.field === 'c') {
      setOrdeParams(orderParams.map((item, index) =>
        index === currentFocusField.index ? {
          ...item,
          orderC: event.target.value
        } : item
      ));
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      { isProgressing ?
        <Box sx={{ marginTop: 9, flexGrow: 1, textAlign: "center" }}>
          <CircularProgress />
        </Box>
      :
        <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: 8, flexGrow: 1 }} noValidate>
          <Toolbar disableGutters>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedDrawDay}
              onChange={e => handleDayChange(e)}
            >
              {drawDay && drawDay.map((item, index) => (
                <MenuItem key={index} value={index}>{item} Day</MenuItem>
              ))}
            </Select>
            <Button
              fullWidth
              sx={{ flexGrow: 1, marginLeft: '5px' }}
              variant="contained"
              onClick={e => handleBoxButtonClick('box')}
              size="small"
              color="info"
            >
              Box *0000
            </Button>
            <Button
              fullWidth
              sx={{ flexGrow: 1, marginLeft: '5px' }}
              variant="contained"
              onClick={e => handleBoxButtonClick('ibox')}
              size="small"
              color="info"
            >
              iBox **0000
            </Button>
            <Button
              fullWidth
              sx={{ flexGrow: 1, marginLeft: '5px' }}
              variant="contained"
              onClick={e => handleBoxButtonClick('4ds')}
              size="small"
              color="info"
            >
              4DS 0000**
            </Button>
          </Toolbar>
          <Toolbar disableGutters>
            {selectedDrawCounter && selectedDrawCounter.split("").map((item, index) => (
              <Fab key={index} size="small" sx={index > 0 ? {marginLeft: 1, boxShadow: "none"} : {boxShadow: "none"}}
                color="secondary" onClick={e => handleCounterClick(item)}>
                {item}
              </Fab>
            ))}
          </Toolbar>
          <TableContainer sx={{marginBottom: 1}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>Counter</TableCell>
                  <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>Num</TableCell>
                  {user.informat?.substring(1) === "BS4A" ?
                    <>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>B</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>S</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>4A</TableCell>
                    </>
                  : user.informat?.includes("BS4AAC") &&
                    <>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>B</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>S</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>4A</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>A</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>C</TableCell>
                    </>
                  }
                  {user.informat?.includes("BS4ACA") &&
                    <>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>B</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>S</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>4A</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>C</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>A</TableCell>
                    </>
                  }
                  {user.informat?.includes("BSCA4A") &&
                    <>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>B</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>S</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>C</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>A</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>4A</TableCell>
                    </>
                  }
                  {user.informat?.includes("BSAC4A") &&
                    <>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>B</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>S</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>A</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>C</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>4A</TableCell>
                    </>
                  }
                  {user.informat?.substring(1) === "SB4A" ?
                    <>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>S</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>B</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>4A</TableCell>
                    </>
                  : user.informat?.includes("SB4AAC") &&
                    <>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>S</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>B</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>4A</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>A</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>C</TableCell>
                    </>
                  }
                  {user.informat?.includes("SB4ACA") &&
                    <>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>S</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>B</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>4A</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>C</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>A</TableCell>
                    </>
                  }
                  {user.informat?.includes("SBCA4A") &&
                    <>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>S</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>B</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>C</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>A</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>4A</TableCell>
                    </>
                  }
                  {user.informat?.includes("SBAC4A") &&
                    <>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>S</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>B</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>A</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>C</TableCell>
                      <TableCell sx={{padding:0, textAlign:"center", fontWeight: "bold"}}>4A</TableCell>
                    </>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {arr.map((item, index) => (
                  <TableRow key={item}>
                    <TableCell sx={{padding:0}}>
                      <TextField
                        fullWidth
                        size="small"
                        value={orderParams[index].counter}
                        inputProps={{ inputMode: "none", style: { padding: 3, width: 75, textAlign: 'center' }}}
                        onFocus={e => setCurrentFocusField({field: 'counter', index: index})}
                        variant="filled"
                      />
                    </TableCell>
                    <TableCell sx={{padding:0}}>
                      <TextField
                        fullWidth
                        size="small"
                        value={orderParams[index].number}
                        inputProps={{ inputMode: "tel", style: { padding: 3, width: 55 }}}
                        onFocus={e => setCurrentFocusField({field: 'number', index: index})}
                        onChange={e => handleValueChange(e)}
                      />
                    </TableCell>
                    <TableCell sx={{padding:0}}>
                      <TextField
                        fullWidth
                        size="small"
                        value={orderParams[index].orderBig}
                        inputProps={{ inputMode: "tel", style: { padding: 3 }}}
                        onFocus={e => setCurrentFocusField({field: 'big', index: index})}
                        onChange={e => handleValueChange(e)}
                      />
                    </TableCell>
                    <TableCell sx={{padding:0}}>
                      <TextField
                        fullWidth
                        size="small"
                        value={orderParams[index].orderSmall}
                        inputProps={{ inputMode: "tel", style: { padding: 3 }}}
                        onFocus={e => setCurrentFocusField({field: 'small', index: index})}
                        onChange={e => handleValueChange(e)}
                      />
                    </TableCell>
                    {user.informat?.substring(1) !== "BS4A" && user.informat?.substring(1) !== "SB4A" &&
                      <>
                        <TableCell sx={{padding:0}}>
                          <TextField
                            fullWidth
                            size="small"
                            value={orderParams[index].orderA}
                            inputProps={{ inputMode: "tel", style: { padding: 3 }}}
                            onFocus={e => setCurrentFocusField({field: 'a', index: index})}
                            onChange={e => handleValueChange(e)}
                          />
                        </TableCell>
                        <TableCell sx={{padding:0}}>
                          <TextField
                            fullWidth
                            size="small"
                            value={orderParams[index].orderC}
                            inputProps={{ inputMode: "tel", style: { padding: 3 }}}
                            onFocus={e => setCurrentFocusField({field: 'c', index: index})}
                            onChange={e => handleValueChange(e)}
                          />
                        </TableCell>
                      </>
                    }
                    <TableCell sx={{padding:0}}>
                      <TextField
                        fullWidth
                        size="small"
                        value={orderParams[index].order4A}
                        inputProps={{ inputMode: "tel", style: { padding: 3 }}}
                        onFocus={e => setCurrentFocusField({field: '4a', index: index})}
                        onChange={e => handleValueChange(e)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TextField
            fullWidth
            name="phone"
            inputProps={{ inputMode: "tel", style: { padding: 8 }}}
            placeholder={t("order.label.phone.number")}
          />
          <Toolbar disableGutters sx={{marginTop: 1}}>
            <Button
              fullWidth
              type="reset"
              onClick={handleReset}
              sx={{ flexGrow: 1, marginRight: '5px' }}
              variant="outlined"
              startIcon={<RotateLeftIcon />}
            >
              {t("button.label.reset")}
            </Button>
            {isLoadingButton ? (
              <LoadingButton
                fullWidth
                loading
                loadingPosition="end"
                endIcon={<SendIcon />}
                variant="outlined"
                sx={{ flexGrow: 1 }}
              >
                {t("button.label.loading")}
              </LoadingButton>
            ) : (
              <Button
                fullWidth
                type="submit"
                sx={{ flexGrow: 1 }}
                variant="contained"
                color="success"
                endIcon={<SendIcon />}
              >
                {t("button.label.order")}
              </Button>
            )}
          </Toolbar>
        </Box>
      }
    </Container>
  );
};