import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../hooks/useAuth";

import { Container, Box, Grid, Paper } from "@mui/material";
import { experimentalStyled as styled } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const UserAccountsPage = ({ setTitle }) => {
  const { user, loginMidlet, logout } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    setTitle("Acounts");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 9, flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {user.userAccounts?.map((account, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Item onClick={() => loginMidlet(account)}>
                <img src={account.image} alt={account.gameType} height={50} />
                <p>{account.gameType}</p>
              </Item>
            </Grid>
          ))}
          <Grid item xs={2} sm={4} md={4} key={100}>
            <Item onClick={logout}>
              <LogoutIcon color="secondary" fontSize="large" sx={{height: 50}}/>
              <p>{t("menu.item.logout")}</p>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
