import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBar } from "./AppBar";
import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from "react-i18next";
import { toastErr } from "../utils/utils";

const APP_IDLE_TIMEOUT = process.env.REACT_APP_IDLE_TIMEOUT;

export const ProtectedLayout = ({pages, title, backToPath}) => {
  const { user, logout } = useAuth();
  const outlet = useOutlet();
  const { t } = useTranslation();

  const { getRemainingTime } = useIdleTimer({
    timeout: parseInt(APP_IDLE_TIMEOUT),
    onIdle: () => {
      toastErr(t("error.session.timeout"));
      logout();
    }
  });

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <AppBar
        pages={pages}
        title={title}
        backToPath={backToPath}
      />
      {outlet}
    </div>
  );
};
