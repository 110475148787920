import { useNavigate } from "react-router-dom";

import { Container, Box } from "@mui/material";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from "@mui/material";

export const MenuListPage = ({ pages }) => {
  const navigate = useNavigate();

  const handleNavigate = (path, title, type) => {
    var state;
    if (title && type) {
      state = {
        state: {
          searchTitle: title,
          searchType: type
        }
      }
    }
    navigate(path, state);
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 7, flexGrow: 1 }}>
        <nav aria-label="main mailbox folders">
          <List>
          {pages?.map((page, index) => (
            <>
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => handleNavigate(page.path, page.label, page.type)}>
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText primary={page.label} primaryTypographyProps={{fontSize: 20}} />
                </ListItemButton>
              </ListItem>
              <Divider key={`div ${index}`}/>
            </>
          ))}
          </List>
        </nav>
      </Box>
    </Container>
  );
};
