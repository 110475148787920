import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { apiHandle } from "../../hooks/services/apiServices";
import { isPostErrorResult, getEditOrderText, toastErr } from "../../utils/utils";

import ShareButton from "../../components/ShareButton";
import { Container, Box, Button, TextField, Toolbar } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { CustomTextOrder } from "../../components/CustomTextOrder";

const APP_AGENT = process.env.REACT_APP_AGENT;

export const HistoryOrderResultPage = ({ setTitle, setBackToPath }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const [result, setResult] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [isCancelOrder, setCancelOrder] = useState(false);

  useEffect(() => {
    setTitle(t("menu.item.history"));

    if (location.state) {
      setBackToPath({ pathName: "/history/search-result", state: {
        searchTitle: location.state.searchTitle,
        searchType: location.state.searchType,
        searchNumber: location.state.searchNumber,
        searchDate: location.state.searchDate
      }});

      if (location.state.action === "EDIT") setResult(getEditOrderText(location.state.orderResult));
      else setResult(location.state.orderResult);
      setCancelOrder(location.state.action === "CANCELLED");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingButton(true);
    const data = new FormData(event.currentTarget);

    apiHandle.order({
      token: user.accessToken,
      orderText: data.get("orderText")
    }).then(result => {
      if (result.data && result.data !== "") {
        if (isPostErrorResult(result.data)) {
          toastErr(result.data);
        } else {
          let resultArr = result.data.split(";");
          setResult(resultArr[0]);
          setCancelOrder(true);
        }
      } else toastErr(t("error.common.app"));
      setLoadingButton(false);
    }).catch(e => {
      setLoadingButton(false);
      toastErr(e);
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: 7, flexGrow: 1 }} noValidate>
        <Toolbar disableGutters>
          { isCancelOrder ?
            <ShareButton content={result}/>
          : loadingButton ?
            <LoadingButton
              loading
              loadingPosition="end"
              endIcon={<SendIcon />}
              variant="outlined"
              sx={{ flexGrow: 1 }}
            >
              {t("button.label.loading")}
            </LoadingButton>
          :
            <Button
              type="submit"
              sx={{ flexGrow: 1 }}
              variant="contained"
              color="success"
              endIcon={<SendIcon />}
            >
              {t("button.label.order")}
            </Button>
          }
        </Toolbar>
        {APP_AGENT === "BR" ?
          <TextField
            fullWidth
            multiline
            autoFocus
            name="orderText"  
            value={result}
            onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
            onChange={(e) => setResult(e.target.value)}
            margin="dense"
            inputProps={{
              readOnly: isCancelOrder,
            }}
          />
        : !isCancelOrder && (/iPad|iPhone|Android/.test(navigator.userAgent)) ?
          <CustomTextOrder orderTextValue={result} setOrderTextValue={setResult} />
          :
          <TextField
            fullWidth
            multiline
            autoFocus
            type="text"
            name="orderText"
            value={result}
            onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
            onChange={(e) => setResult(e.target.value)}
            margin="dense"
            inputProps={{
              readOnly: isCancelOrder,
            }}
          />
        }
      </Box>
    </Container>
  );
}