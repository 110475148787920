import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { apiHandle, verifyPassword } from "../../hooks/services/apiServices";
import { isPostErrorResult, getEditOrderText, toastErr } from "../../utils/utils";

import ShareButton from "../../components/ShareButton";
import { Container, Box, Button, TextField, Toolbar } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { CustomTextOrder } from "../../components/CustomTextOrder";

const APP_AGENT = process.env.REACT_APP_AGENT;

export const OperationSearchResultPage = ({ setTitle, setBackToPath }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const [searchType, setSearchType] = useState();
  const [result, setResult] = useState();
  const [invoiceno, setInvoiceno] = useState();
  const [password, setPassword] = useState();
  const [buttonInfo, setButtonInfo] = useState({});
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [isCancelOrder, setCancelOrder] = useState(false);
  const [isReadOnly, setReadOnly] = useState(false);
  const [isPwdDialogOpen, setPwdDialogOpen] = useState(false);

  useEffect(() => {
    setTitle(t("menu.item.operation"));

    if (location.state) {
      setBackToPath({ pathName: "/operation/search", state: {
        searchTitle: location.state.searchTitle,
        searchType: location.state.searchType
      }});

      if (location.state.action === "EDIT") setResult(getEditOrderText(location.state.searchResult));
      else setResult(location.state.searchResult);
      setSearchType(location.state.searchType);
      setInvoiceno(location.state.invoiceno);
      setReadOnly(location.state.action === "CANCELLED");
      setCancelOrder(location.state.searchType === "pekkong");

      switch (location.state.searchType) {
        case "resend":
          setButtonInfo({
            label: t("button.label.resend"),
            icon: <ScheduleSendIcon />
          });
          break;
        case "edit":
          setButtonInfo({
            label: t("button.label.order"),
            icon: <EditIcon />
          });
          break;
        case "void":
          setButtonInfo({
            label: t("button.label.void"),
            icon: <CancelIcon />
          });
          break;
        default:
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePwdDialogClose = () => {
    setPwdDialogOpen(false);
    setPassword();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    switch (searchType) {
      case "resend":
      case "void":
        setPwdDialogOpen(true);
        break;
      case "edit":
        setLoadingButton(true);

        apiHandle.order({
          token: user.accessToken,
          orderText: data.get("orderText")
        }).then(result => {
          if (result.data && result.data !== "") {
            if (isPostErrorResult(result.data)) {
              toastErr(result.data);
            } else {
              let resultArr = result.data.split(";");
              setResult(resultArr[0]);
              setCancelOrder(true);
              setReadOnly(true);
            }
          } else toastErr(t("error.common.app"));
          setLoadingButton(false);
        }).catch(e => {
          setLoadingButton(false);
          toastErr(e);
        });
        break;
      default:
        break;
    }
  };

  const handleSubmitVoid = () => {
    if (verifyPassword({
      token: user.accessToken,
      password: password
    })) {
      setLoadingButton(true);

      if (searchType === "void") {
        apiHandle.cancelOrder({
          token: user.accessToken,
          invoiceno: invoiceno
        }).then(result => {
          handlePwdDialogClose();
          setLoadingButton(false);

          if (isPostErrorResult(result.data)) {
            toastErr(result.data);
          } else {
            setCancelOrder(true);
            setResult(result.data);
          }
        }).catch(e => {
          setLoadingButton(false);
          toastErr(e);
        });
      } else if (searchType === "resend") {
        apiHandle.resendOrder({
          token: user.accessToken,
          invoiceno: invoiceno
        }).then(result => {
          handlePwdDialogClose();
          setLoadingButton(false);

          if (isPostErrorResult(result.data)) {
            toastErr(result.data);
          } else {
            setCancelOrder(true);
            setResult(result.data);
          }
        }).catch(e => {
          setLoadingButton(false);
          toastErr(e);
        });
      }
    } else toastErr(t("error.password.invalid"));
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: 7, flexGrow: 1 }} noValidate>
        <Toolbar disableGutters>
          { isCancelOrder ?
          <ShareButton content={result}/>
          : isLoadingButton ?
            <LoadingButton
              loading
              loadingPosition="end"
              endIcon={<SendIcon />}
              variant="outlined"
              sx={{ flexGrow: 1 }}
            >
              {t("button.label.loading")}
            </LoadingButton>
          :
            <Button
              type="submit"
              sx={{ flexGrow: 1 }}
              variant="contained"
              endIcon={buttonInfo.icon}
              color={searchType === "void" ? "error" : "success"}
            >
              {buttonInfo.label}
            </Button>
          }
        </Toolbar>
        {APP_AGENT === "BR" ?
          <TextField
            fullWidth
            multiline
            autoFocus
            name="orderText"  
            value={result}
            onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
            onChange={(e) => setResult(e.target.value)}
            margin="dense"
            inputProps={{
              readOnly: isReadOnly,
            }}
          />
        : !isReadOnly && (/iPad|iPhone|Android/.test(navigator.userAgent)) ?
          <CustomTextOrder orderTextValue={result} setOrderTextValue={setResult} />
          :
          <TextField
            fullWidth
            multiline
            autoFocus
            type="text"
            name="orderText"
            value={result}
            onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
            onChange={(e) => setResult(e.target.value)}
            margin="dense"
            inputProps={{
              readOnly: isReadOnly,
            }}
          />
        }
      </Box>

      <Dialog open={isPwdDialogOpen} onClose={handlePwdDialogClose}>
        <DialogTitle>{searchType === "void" ? t("option.label.void") : t("option.label.resend")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {searchType === "void" ? t("dialog.password.void.desc") : t("dialog.password.resend.desc")}
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePwdDialogClose} color="secondary">{t("button.label.cancel")}</Button>
          {isLoadingButton ? (
            <LoadingButton
              loading
              variant="outlined"
            >
              {searchType === "void" ? t("button.label.void") : t("button.label.resend")}
            </LoadingButton>
          ) : (
            <Button onClick={handleSubmitVoid} color="error" variant="contained">
              {searchType === "void" ? t("button.label.void") : t("button.label.resend")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
}