import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { isPostErrorResult, toastErr } from "../utils/utils";
import { apiHandle } from "../hooks/services/apiServices";

import { Container, Box, Button, TextField, Toolbar, Typography } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { Search } from "@mui/icons-material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import LoadingButton from '@mui/lab/LoadingButton';

export const WinlosePage = ({ setTitle, setBackToPath }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchDateFrom, setSearchDateFrom] = useState(new Date());
  const [searchDateTo, setSearchDateTo] = useState(new Date());
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setTitle(t("menu.item.winlose"));
    setBackToPath({ pathName: "/dashboard" });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDateFrom = (newDate) => {
    setSearchDateFrom(newDate);
  }

  const handleChangeDateTo = (newDate) => {
    setSearchDateTo(newDate);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingButton(true);

    apiHandle.getWinloseSearch({
      token: user.accessToken,
      dateFrom: (searchDateFrom && moment(searchDateFrom).format("DDMMYY")),
      dateTo: (searchDateTo && moment(searchDateTo).format("DDMMYY"))
    }).then(result => {
      if (isPostErrorResult(result.data)) {
        toastErr(result.data);
      } else {
        navigate("/winlose/result", {
          state: {
            searchResult: result.data
          }
        });
      }
      setLoadingButton(false);
    }).catch(e => {
      setLoadingButton(false);
      toastErr(e);
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: 8, flexGrow: 1 }} noValidate>
        <Toolbar disableGutters>
          <Typography component="div" sx={{ flexGrow: 1 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                inputFormat="DDMMYY"
                value={searchDateFrom}
                onChange={handleChangeDateFrom}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Typography>
          <Typography component="div" sx={{ flexGrow: 1 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                inputFormat="DDMMYY"
                value={searchDateTo}
                onChange={handleChangeDateTo}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Typography>
          {loadingButton ? (
            <LoadingButton
              fullWidth
              loading
              loadingPosition="start"
              startIcon={<Search />}
              variant="outlined"
              sx={{ flexGrow: 1, padding: '15px 0 15px 0', marginLeft: 1 }}
            >
              {t("button.label.search")}
            </LoadingButton>
          ) : (
            <Button
              fullWidth
              type="submit"
              sx={{ flexGrow: 1, padding: '15px 0 15px 0', marginLeft: 1 }}
              variant="contained"
              startIcon={<Search />}
            >
              {t("button.label.search")}
            </Button>
          )}
        </Toolbar>
        <Alert severity="info" sx={{ marginTop: 2 }}>
          <AlertTitle>{t("menu.item.winlose")}</AlertTitle>
          {t("winlose.search")}
        </Alert>
      </Box>
    </Container>
  );
};